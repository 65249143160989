body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(124 58 237);
}

::-webkit-scrollbar-corner {
  overflow: hidden;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(109 40 217);
}
